// Doesn't seem like a composable in the current form
// Would there be some composable to utilize for the window.analytics?
// Put these all under one useAnalytics function if keeping this in composables

export const analyticsIdentify = async (data: any) => {

  const analytics = (window as any).analytics;

  if (data.client) {
    const client = data.client;
    analytics.identify(client.id, {
      email: client.email,
      client_id: client.id,
      created_at: client.created_on._seconds,
    });
  } else if (data.partner) {
    const partner = data.partner;
    analytics.identify(partner.id, {
      email: partner.email,
      partner_id: partner.id,
      created_at: partner.created_on._seconds,
    });
  }
};

export const trackPageView = async (to, from) => {

  const analytics = (window as any).analytics;
  analytics.page(to.name);
};

export const trackPopularButtonClick = async (data?: any) => {

  const analytics = (window as any).analytics;
  analytics.track("Popular Search Button Clicked", {
    search: data.search,
  });
};

export const trackSearchButtonClick = async (data?: any) => {

  const analytics = (window as any).analytics;
  analytics.track("Algolia Results Button Clicked", {
    search: data.search,
  });
};

export const trackCheckoutButtonClick = async () => {

  const analytics = (window as any).analytics;
  analytics.track("Checkout Button Clicked");
};

export const trackOpenMobileSearchModal = async () => {

  const analytics = (window as any).analytics;
  analytics.track("Mobile Search View - Opened");
};

export const trackCloseMobileSearchModal = async () => {

  const analytics = (window as any).analytics;
  analytics.track("Mobile Search View - Closed");
};

export const trackOpenMobileMenu = async () => {

  const analytics = (window as any).analytics;
  analytics.track("Mobile Menu - Opened");
};

export const trackCloseMobileMenu = async () => {

  const analytics = (window as any).analytics;
  analytics.track("Mobile Menu - Closed");
};
