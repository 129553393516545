import { defineStore } from "pinia";

import {
  trackOpenMobileMenu,
  trackCloseMobileMenu,
} from "~~/composables/useAnalytics";

const state = () => ({
  sidebarOpen: false,
  searchModalOpen: false,
});

const getters = {
  isAuthenticated(state): Boolean {
    return state.authUser ? true : false;
  },
};

const actions = {
  openSidebar() {
    trackOpenMobileMenu();
    this.sidebarOpen = true;
  },
  closeSidebar() {
    this.sidebarOpen = false;
  },
  openSearchModalMobile() {
    this.searchModalOpen = true;
  },
  closeSearchModalMobile() {
    this.searchModalOpen = false;
  },
};

export const useNavigationStore = defineStore("navigationStore", {
  state,
  getters,
  actions,
});
